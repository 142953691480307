.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

* {
  font-family: "Poppins";
}

*:focus {
  /* outline: #8d96ff; */
  border-color: #8d96ff !important;
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.steps-content {
  min-height: 250px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 5px;
}

.steps-action {
  margin-top: 20px;
  text-align: center;
}

.steps-action .btn {
  margin-right: 10px;
  width: 200px;
}

/* index.css */

/* Regular */
@font-face {
  font-family: "Poppins";
  src: url("../src/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "Poppins";
  src: url("../src/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Semi-Bold */
@font-face {
  font-family: "Poppins";
  src: url("/src/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  /* Semi-Bold weight */
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/src/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  /* Medium weight */
  font-style: normal;
}

/* Add more variations as needed */

.ant-layout {
  background-color: white !important;
}

/* body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
} */
