@media screen and (max-width: 600px) {
  .success_desc_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cont1 {
    width: 50% !important;
    /* text-align: center; */
    order: 1;
    margin: auto;
  }

  .cont2 {
    width: 80% !important;
    /* text-align: center; */
    order: 1;
  }
}
