label {
  color: black !important;
}

button,
input,
optgroup,
select,
textarea {
  margin-right: 10px;
  position: relative;
  border: 1px solid #c4dbf9;
  border-radius: 0.525rem;

  padding: 10px;
  font-size: 16px;
  width: 100%;
}

select:focus {
  border: 1px solid #c4dbf9;
  outline: 1px solid #c4dbf9;
}

.rsd__select-container-day {
  margin-right: 10px;
}

.rsd__select-container-month {
  margin-right: 10px;
}

#select-form-container .ant-row {
  margin: 1% 0 5%;
}

#select-form-container
  .ant-row
  .ant-col
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-selector {
  border-radius: 8px;
  height: 50px;
  display: flex;
  align-items: center;
}

#select-form-container
  .ant-row
  .ant-col
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d252d80;
}

.registration-form-headings {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  font-family: "Poppins";
}

@media (max-width: 961px) {
  #businesstype {
    justify-content: space-between;
  }
  #businesstype .rsd__select-container-day {
    width: 30%;
  }
  #businesstype .rsd__select-container-month {
    width: 30%;
  }
  #businesstype .rsd__select-container-year {
    width: 30%;
  }
}
