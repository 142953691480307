.ant-modal-footer {
  display: flex;
}

.ant-modal-header {
  padding: 16px 45px;
}

.ant-modal-body > p {
  color: black;
}

.ant-modal-confirm-btns {
  display: flex;
}

.ant-modal-close {
  width: auto;
}

@media screen and (max-width: 600px) {
  .setMargin {
    margin-left: 0px !important;
  }

  .setWidth {
    width: 100% !important;
  }
}
