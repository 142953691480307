.ant-collapse-header .ant-collapse-arrow > svg {
  fill: #919afd;
  /* change to your preferred color */
}

.ant-switch-checked {
  background-color: #919afd !important;
}

.para_style {
  color: black;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputBg {
  background-color: #eff0fd !important;
  margin-bottom: 10px;
  border-radius: 8px;
  border: none;
  /* padding: 5px !important; */
}

.inputBg .ant-input {
  background-color: #eff0fd !important;
}

.custom-modal .ant-modal-content {
  border-radius: 20px;
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.15);
}

.custom-modal .ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.custom-modal .ant-modal-body {
  padding: 24px;
}

.custom-modal .ant-modal-footer {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.ant-modal-header {
  padding-left: 20px !important;
}

.ant-modal-content {
  padding: 10px 0;
}

.ant-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .password_width {
    width: 70% !important;
  }
}
